<template>
  <AdminPage contentComponent="AppAdminSpaceDetail" />
</template>

<script>
import AdminPage from '@/templates/admin/AppAdminPage'

export default {
  name: 'TheSpaceDetail',
  components: {
    AdminPage,
  },
}
</script>
